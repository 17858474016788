<template>
    <section class="swiper-container">
        <Swiper
            :dir="rtl"
            :slidesPerGroup="3"
            :modules="[SwiperAutoplay, SwiperNavigation, SwiperPagination]"
            :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
            spaceBetween="15"
            :breakpoints="{
                '0': {
                    slidesPerView: 1.2,
                    slidesPerGroup: 1,
                },
                '500': {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                },
                '992': {
                    slidesPerView: 2.5,
                    slidesPerGroup: 1,
                },
                '1280': {
                    slidesPerView: 3,
                },
            }"
        >
            <swiper-slide v-for="item in data" :key="item.id">
                <CategoryCard
                    :imgURL="item?.logo"
                    :cardTitle="item?.name"
                    :location="`${item?.includes?.country?.name}, ${item?.includes?.city?.name || ''}`"
                    :link="`/${category}/${item?.id}`"
                />
            </swiper-slide>
        </Swiper>
    </section>
</template>

<script setup>
import CategoryCard from './CategoryCard.vue';
import { useCommonStore } from '../../../store/search/common';

const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);

const props = defineProps({
    data: {
        type: Array,
        default: [],
    },
});

const { locale } = useI18n();
const rtl = computed(() => locale.value === 'ar');
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.swiper-container {
    margin-bottom: toRem(20);
    .swiper {
        padding: 0 0.625rem 0.625rem;
        .swiper-slide {
            height: auto;
        }
    }
}
</style>
