<template>
    <div class="box-container">
        <div class="box-header">
            <h3 class="label">{{ $t('appFees') }}</h3>
        </div>
        <div class="tags-container">
            <div class="switch-container">
                <InputSwitch v-model="checked" @update:modelValue="updateSelectedItems" />
                <span class="hint">{{ $t('advSearch.filter.appFeesHint') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCheckCategory } from '../../../../composables/useCheckCategory';

const { t } = useI18n();
const { categoryFilterAppFees } = useCheckCategory();
const emit = defineEmits(['update:checked']);
const checked = ref(false);

onMounted(() => {
    checked.value = categoryFilterAppFees.value == 0 ? false : true;
    updateSelectedItems();
});

const updateSelectedItems = () => {
    const item = checked.value ? [{ id: 1, text: t('advSearch.filter.withoutFees'), type: 'appFees' }] : [];
    emit('update:checked', item);
};
</script>

<style lang="scss">
@import 'libs/shared/assets/scss/main.scss';
.box-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .box-header {
        display: flex;
        flex-direction: column;
        .label {
            font-size: toRem(16);
            color: $dark-gray;
            margin-bottom: toRem(7);
        }
    }
    .switch-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: $dark-gray;
        font-size: toRem(14);
        line-height: 1;
    }
}
</style>
