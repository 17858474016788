export const useCitiesStore = defineStore('cities', () => {
    const { $getApi } = useNuxtApp();

    const countryCities = ref([]);
    const citiesLoading = ref(false);

    const getCountryCities = async (id, keyword = '') => {
        let query = `countries/${id}/cities?per_page=200`;
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }
        citiesLoading.value = true;
        try {
            const res = await $getApi(query);

            if (keyword) {
                let value;
                value = [...res.data.data];
                return value;
            } else {
                countryCities.value = res.data?.data || [];
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
        } finally {
            citiesLoading.value = false;
        }
    };

    return { countryCities, citiesLoading, getCountryCities };
});
