<template>
    <section class="tab1-container">
        <!-- Category Cards -->
        <template v-if="categoryList.length || categoryCourses.length">
            <section class="swiper-section">
                <SectionTitle :icon="universityImg" :title="sectionTitle" :total="categoryListTotal" />
                <template v-if="categoryList.length">
                    <CategorySwiper :data="categoryList?.slice(0, 3)" />
                    <SeeAll v-if="categoryList.length > 3" :action="() => (activeTab = 2)" />
                </template>
                <NoDataFound v-else :smaller="true" />
            </section>

            <!-- Course Cards -->
            <section class="swiper-section">
                <SectionTitle :icon="courseImg" :title="$t('courses')" :total="categoryCoursesTotal" />
                <template v-if="categoryCourses.length">
                    <CourseSwiper :data="categoryCourses?.slice(0, 4)" />
                    <SeeAll v-if="categoryCourses.length > 4" :action="() => (activeTab = 3)" />
                </template>
                <NoDataFound v-else :smaller="true" />
            </section>
        </template>
        <NoDataFound v-else />

        <!-- Subjects -->
        <template v-if="category === 'university'">
            <SectionTitle :title="$t('Subjects')" />
            <section class="subjects-section">
                <Tag
                    v-for="subject in universitySubjects?.slice(0, 6)"
                    :key="subject?.id"
                    :id="subject?.id"
                    :text="subject?.name"
                    :clickable="true"
                    :active="categoryFilterSubjects.some(item => item.id === subject.id)"
                    @click="() => addSubject(subject)"
                />
            </section>
        </template>
    </section>
</template>

<script setup>
import universityImg from '../../../../libs/shared/assets/images/search/university.png';
import courseImg from '../../../../libs/shared/assets/images/search/course_cap_3.png';
import SeeAll from '../../general/SeeAll.vue';
import Tag from '../../general/Tag.vue';
import SectionTitle from '../common/SectionTitle.vue';
import CategorySwiper from '../common/CategorySwiper.vue';
import CourseSwiper from '../common/CourseSwiper.vue';
import { useCommonStore } from '../../../store/search/common';
import { useUniversityStore } from '../../../store/search/university';
import { useCheckCategory } from '../../../composables/useCheckCategory';
import NoDataFound from '../common/NoDataFound.vue';

const commonStore = useCommonStore();
const universityStore = useUniversityStore();

const { activeTab, category } = storeToRefs(commonStore);
const { universitySubjects } = storeToRefs(universityStore);
const {
    sectionTitle,
    categoryList,
    categoryCourses,
    categoryListTotal,
    categoryCoursesTotal,
    categoryFilterSubjects,
    getCategoryList,
    getCategoryCourses,
} = useCheckCategory();

const addSubject = subject => {
    const index = categoryFilterSubjects.value.findIndex(existingSubject => existingSubject.id === subject.id);

    if (index === -1) {
        categoryFilterSubjects.value.push({ id: subject.id, text: subject.name, type: 'subject' });
    } else {
        categoryFilterSubjects.value.splice(index, 1);
    }
    // call APIs
    getCategoryList.value();
    getCategoryCourses.value();
};
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.tab1-container {
    .swiper-section {
        margin-bottom: toRem(40);
    }
    .subjects-section {
        display: flex;
        flex-wrap: wrap;
        gap: toRem(10);
    }
}
@include mediaMin($lg) {
    .tab1-container {
        .swiper-section {
            margin-bottom: toRem(60);
        }
    }
}
</style>

